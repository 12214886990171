import { TextStyleVariants } from 'components/foundation/Text';
import styled, { css } from 'styled-components';
import breakpointsMedia from 'theme/utils/breakpointsMedia';

const bannerFormats = {
  horizontal: () => css`
    min-height: 6rem;

    ${breakpointsMedia({
      md: css`
        min-height: 16rem;
      `,
    })}
  `,
  square: () => css`
    margin: 0 auto;
    max-width: 35rem;
    min-height: 27rem;
  `,
  sticky: () => css`
    ${breakpointsMedia({
      md: css`
        top: 10.5rem;
        position: sticky;
      `,
    })}
  `,
};

export const StaticBannerWrapper = styled.div`
  ${({ theme, $sticky }) => css`
    width: 100%;
    position: relative;

    ${!!$sticky && bannerFormats.sticky()}

    &::after {
      display: block;
      text-align: center;
      content: 'Publicidade';
      color: ${theme.colors.base['500']};
      ${TextStyleVariants({
        $variant: { typography: 'bodyTextXXSmall' },
      })};
    }
  `}
`;

export const Picture = styled.picture`
  ${({ theme, $bannerFormat }) => css`
    width: 100%;
    display: block;
    position: relative;

    ${bannerFormats[$bannerFormat] || bannerFormats.horizontal()};

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: ${theme.borderRadius};
    }
  `}
`;
