import styled, { css } from 'styled-components';
import breakpointsMedia from 'theme/utils/breakpointsMedia';

export const LiveOddsWrapper = styled.div`
  position: relative;

  ${({ theme }) => css`
    .head-to-head__wrapper {
      margin: ${theme.spacings.md} 0;
    }

    .live-odds__slider-item {
      width: 100%;
      min-height: 184px;
      padding: ${theme.spacings.sm};
      border-radius: ${theme.borderRadius};
      background-color: ${theme.colors.base['50']};

      ${breakpointsMedia({
        lg: css`
          width: auto;
          min-height: 196px;
        `,
      })}
    }

    .swiper {
      .swiper-pagination {
        display: none;
        height: 0.4rem;
        justify-content: center;
        gap: ${theme.spacings.xs};
        bottom: ${theme.spacings.sm};

        ${breakpointsMedia({
          lg: css`
            display: flex;
          `,
        })}

        .swiper-pagination-bullet {
          margin: 0;
          opacity: 1;
          width: 2.4rem;
          height: 0.4rem;
          border-radius: 0.2rem;
          background-color: ${theme.colors.base['200']};

          &.swiper-pagination-bullet-active {
            background-color: ${theme.colors.base['600']};
          }
        }
      }
    }
  `}
`;

export const NumberPagination = styled.div`
  ${({ theme }) => css`
    position: absolute;
    border-radius: 1.6rem;
    top: ${theme.spacings.sm};
    right: ${theme.spacings.sm};
    padding: 0.2rem ${theme.spacings.sm};
    border: 0.5px solid ${theme.colors.base['600']};
  `}
`;
