import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import PropTypes from 'prop-types';

import Text from 'components/foundation/Text';
import BetnacionalLogo from 'theme/Logo/BetnacionalLogo';

import * as S from './styles';

const Odd = ({ title, oddValue, variant }) => {
  const placeholderImage = '/images/placeholder.jpg';

  const titleMappings = {
    1: variant ? (
      <Image
        width={18}
        height={18}
        src={variant.homeImage || placeholderImage}
        alt='Time da casa'
      />
    ) : (
      '1'
    ),
    X: variant ? variant.drawText : 'X',
    2: variant ? (
      <Image
        width={18}
        height={18}
        src={variant.awayImage || placeholderImage}
        alt='Visitante'
      />
    ) : (
      '2'
    ),
  };

  return (
    <S.Odd className='odds__odd'>
      <Text
        as='span'
        className='odd__title'
        $csscolor='500'
      >
        {titleMappings[title] || title}
      </Text>
      <Text
        as='span'
        className='odd__value'
        $variant={{ typography: 'bodyTextXXXSmallBold' }}
      >
        {oddValue}
      </Text>
    </S.Odd>
  );
};

Odd.propTypes = {
  title: PropTypes.string.isRequired,
  oddValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  variant: PropTypes.shape({
    homeImage: PropTypes.string,
    drawText: PropTypes.string,
    awayImage: PropTypes.string,
  }),
};

const Odds = ({
  homeOdd,
  drawOdd,
  awayOdd,
  variant,
  matchId,
  onlyOdds,
  className,
  singleLine,
  hideBetButton,
  externalLink = 'https://betnacional.com/live/1?p=GameArena',
}) => (
  <S.Odds
    variant={variant}
    onlyOdds={onlyOdds}
    singleLine={singleLine}
    className={className}
  >
    <div className='odd__container'>
      <Link
        target='_blank'
        prefetch={false}
        href={`https://betnacional.com/event/1/1/${matchId}?p=GameArena`}
      >
        <Odd
          title='1'
          oddValue={homeOdd?.substring(0, 4) ?? 0}
          variant={variant}
        />
      </Link>
      <Link
        target='_blank'
        prefetch={false}
        href={`https://betnacional.com/event/1/1/${matchId}?p=GameArena`}
      >
        <Odd
          title='X'
          oddValue={drawOdd?.substring(0, 4) ?? 0}
          variant={variant}
        />
      </Link>
      <Link
        target='_blank'
        prefetch={false}
        href={`https://betnacional.com/event/1/1/${matchId}?p=GameArena`}
      >
        <Odd
          title='2'
          oddValue={awayOdd?.substring(0, 4) ?? 0}
          variant={variant}
        />
      </Link>
    </div>

    {!hideBetButton ? (
      <S.BetButton className='odd__bet-button'>
        <Link
          target='_blank'
          prefetch={false}
          href={externalLink}
        >
          <Text
            as='span'
            $csscolor='00'
            $variant={{ typography: 'bodyTextTinyBold' }}
          >
            Aposte com
          </Text>
          <BetnacionalLogo />
        </Link>

        <Text
          as='p'
          $csscolor='00'
          $variant={{ typography: 'bodyTextTinySmallBold' }}
        >
          Aposte com responsabilidade
        </Text>
      </S.BetButton>
    ) : null}
  </S.Odds>
);

Odds.propTypes = {
  onlyOdds: PropTypes.bool,
  homeOdd: PropTypes.string,
  drawOdd: PropTypes.string,
  awayOdd: PropTypes.string,
  singleLine: PropTypes.bool,
  className: PropTypes.string,
  hideBetButton: PropTypes.bool,
  externalLink: PropTypes.string,
  matchId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.shape({
    homeImage: PropTypes.string,
    drawText: PropTypes.string,
    awayImage: PropTypes.string,
  }),
};

export default React.memo(Odds);
