// const API_URL = process.env.WP_API_URL || process.env.apiUrl;
const API_URL = 'https://gamearena.gg/wp-json/news';
const API_URL_CLIENT = 'https://gamearena.gg/wp-json/news';
const API_URL_PREVIEW = 'https://gamearena.gg/wp-json/preview';
const API_URL_ODDS = process.env.API_BETNACIONAL || process.env.apiUrlOdds;
const API_URL_ODDS_CLIENT = process.env.apiUrlOdds;
const API_URL_SCORE = process.env.API_SCORE || process.env.apiScore;
const API_URL_SCORE_CLIENT = 'https://scores-api.gamearena.gg';

const NO_CACHE_HEADER = process.env.isDev
  ? {
      'Cache-Control': 'public, max-age=1, s-maxage=1, must-revalidate',
      Pragma: 'no-cache',
    }
  : {
      'Cache-Control': 'public, max-age=900, s-maxage=900, must-revalidate',
      Pragma: 'no-cache',
    };

const fetchAPIWPRestGet = async ({
  path,
  ssr = false,
  customHeaders = {},
  previewMode = false,
}) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...customHeaders,
  };

  let url = `${ssr ? API_URL : API_URL_CLIENT}${path}`;

  if (previewMode) {
    url = `${API_URL_PREVIEW}${path}`;
  }

  const res = await fetch(url, {
    headers,
    method: 'GET',
    cache: 'no-store',
    next: { revalidate: 1 },
  });

  // console.log('url :>> ', url);

  // console.log('res :>> ', res.headers.get('cf-cache-status'));

  // error handling work
  if (res.status === 404 || res.status === 400) {
    console.error('error details', url);

    return {
      error: true,
    };
  }

  const json = await res.json();

  if (json.errors) {
    console.error('error data', json.errors);
    console.error('error details', url);
    // throw new Error('Failed to fetch API');
  }

  if (json?.seo && json?.data) {
    json.data.seo = json.seo;
  }

  return json?.data || json;
};

export default fetchAPIWPRestGet;

export const fetchOdds = async ({
  ssr = false,
  customHeaders,
  path = '/sports/1/markets/1/live-events',
}) => {
  const headers = {
    // ...NO_CACHE_HEADER,
    ...customHeaders,
  };

  const url = `${ssr ? API_URL_ODDS : API_URL_ODDS_CLIENT}${path}`;
  const res = await fetch(url, {
    headers,
    cache: 'no-store',
    next: { revalidate: 1 },
  });

  // error handling work
  if (res.status === 404 || res.status === 400) {
    console.error('error details', url);

    return {
      error: true,
    };
  }

  if (res.status === 403) {
    console.error('error details', url);

    return {
      url,
      rayId: res?.headers?.get('cf-ray'),
      errorStatusCode: res.status,
      redirect: {
        permanent: false,
        destination: '/403',
      },
    };
  }

  const data = await res.json();

  if (data.errors) {
    console.error('error data', data.errors);
    console.error('error details', url);
  }

  return data?.data || data;
};

export const fetchScoresMatch = async ({
  matchId,
  path = '',
  ssr = false,
  customHeaders = {},
  modality = 'soccer',
}) => {
  const headers = {
    ...NO_CACHE_HEADER,
    ...customHeaders,
  };
  const url = `${ssr ? API_URL_SCORE : API_URL_SCORE_CLIENT}/${modality}/match/${encodeURIComponent(matchId)}${path}`;
  const res = await fetch(url, {
    headers,
  });

  // error handling work
  if (res.status === 404 || res.status === 400) {
    console.error('error details', url);

    return {
      error: true,
    };
  }

  if (res.status === 403) {
    console.error('error details', url);

    return {
      url,
      errorStatusCode: res.status,
      redirect: {
        permanent: false,
        destination: '/403',
      },
    };
  }

  const data = await res.json();

  if (data.errors) {
    console.error('error data', data.errors);
    console.error('error details', url);
  }

  return data || {};
};

export const fetchScoresApi = async ({
  path = '',
  ssr = false,
  customHeaders = {},
}) => {
  const headers = {
    ...NO_CACHE_HEADER,
    ...customHeaders,
  };
  const url = `${ssr ? API_URL_SCORE : API_URL_SCORE_CLIENT}${path}`;
  const res = await fetch(url, {
    headers,
  });

  // error handling work
  if (res.status === 404 || res.status === 400) {
    console.error('error details', url);

    return {
      error: true,
    };
  }

  if (res.status === 403) {
    console.error('error details', url);

    return {
      url,
      errorStatusCode: res.status,
      redirect: {
        permanent: false,
        destination: '/403',
      },
    };
  }

  const data = await res.json();

  if (data.errors) {
    console.error('error data', data.errors);
    console.error('error details', url);
  }

  return data || {};
};

export const getMenus = async ({ ssr = false, customHeaders } = {}) => {
  try {
    const data = await fetchAPIWPRestGet({
      ssr,
      customHeaders,
      path: '/menus?location=novo-menu-principal,menu-rodape',
    });

    // // Normalize data menu primary
    // data.menus['menu-principal'] = Object.keys(
    //   data.menus['menu-principal']
    // ).map(key => data.menus['menu-principal'][key]);

    // // Normalize data menu footer
    // data.menus['menu-rodape'] = Object.keys(data.menus['menu-rodape']).map(
    //   key => data.menus['menu-rodape'][key]
    // );

    return data || [];
  } catch (error) {
    console.error(
      'An error occurred while trying to return data: getMenus path -> /menus?location=novo-menu-principal,menu-rodape',
      error
    );

    return {
      error,
      erro: true,
    };
  }
};

export const getYoutubeData = async ({
  ssr = false,
  customHeaders,
  params,
} = {}) => {
  try {
    const data = await fetchAPIWPRestGet({
      ssr,
      path: `/youtube?type=${params}`,
      customHeaders,
    });

    return data || {};
  } catch (error) {
    console.error(
      'An error occurred while trying to return data: getYoutubeData path -> /youtube?type=',
      error
    );

    return {
      error,
      erro: true,
    };
  }
};

export const getSearchNews = async ({
  params,
  keyword,
  category,
  ssr = false,
  customHeaders,
} = {}) => {
  try {
    const queryParams = new URLSearchParams({
      keyword,
      ...(category ? { category } : {}),
      ...params,
    }).toString();

    const data = await fetchAPIWPRestGet({
      ssr,
      customHeaders,
      path: `/search?${queryParams}`,
    });

    return data || {};
  } catch (error) {
    console.error(
      'An error occurred while trying to return data: getSearchNews path -> /search?keyword=',
      error
    );

    return {
      error,
      erro: true,
    };
  }
};
