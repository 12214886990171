import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';

import { getBetLives } from 'libs/api/Match';
import { isEmpty, size, take } from 'theme/utils/functions';

import Odds from 'components/data/Odds';
import Text from 'components/foundation/Text';
import HeadToHeadCard from 'components/common/HeadToHeadCard';

import 'swiper/css/pagination';
import 'swiper/swiper-bundle.css';

import * as S from './styles';

const INITIAL_STATE = {
  bets: [],
  liveEvents: {},
  currentSlide: 0,
};

const LIVEODDS_INTERVAL = 10000;

const LiveOdds = ({ data, limit = 8, isMobile, className = '' }) => {
  const intervalId = useRef(null);
  const blockDoubleRequests = useRef(false);
  const [stateLocal, setStateLocal] = useState({
    ...INITIAL_STATE,
    liveEvents: data,
  });

  const { bets, liveEvents, currentSlide } = stateLocal;

  const updateOddsData = useCallback(
    ({ newData }) => {
      const oddsBrazilObjectKeys = Object.keys(newData.oddsBrazil);
      const oddsWordObjectKeys = Object.keys(newData.oddsWord);

      const betsBrazil = take(oddsBrazilObjectKeys, limit);
      const diff = limit - size(betsBrazil);
      const betsWord = take(oddsWordObjectKeys, diff);

      setStateLocal(state => ({
        ...state,
        bets: [...betsBrazil, ...betsWord],
        liveEvents: newData,
      }));
    },
    [limit]
  );

  const getOddsData = useCallback(async () => {
    intervalId.current = setInterval(async () => {
      try {
        const liveEventsData = await getBetLives();

        updateOddsData({ newData: liveEventsData });
      } catch (error) {
        clearInterval(intervalId.current);
      }
    }, LIVEODDS_INTERVAL);
  }, [updateOddsData]);

  useEffect(() => {
    if (
      !isEmpty(data?.live_count) &&
      !blockDoubleRequests.current &&
      data?.oddsBrazil &&
      data?.oddsWord
    ) {
      blockDoubleRequests.current = true;
      updateOddsData({ newData: data });
      getOddsData();
    }
  }, [data, getOddsData, updateOddsData]);

  if (isEmpty(liveEvents?.live_count)) {
    return '';
  }

  return (
    <S.LiveOddsWrapper className={className}>
      <div className='live-odds__slider-item'>
        <Text
          as='h4'
          $csscolor='600'
          $variant={{
            typography: 'heading4',
          }}
        >
          Probabilidades em alta
        </Text>

        <Swiper
          grabCursor
          speed={500}
          spaceBetween={8}
          className='live-odds__slider'
          modules={[Autoplay, Pagination]}
          slidesPerView={isMobile ? 'auto' : 1}
          onSlideChange={swiper =>
            setStateLocal(state => ({
              ...state,
              currentSlide: swiper.activeIndex,
            }))
          }
          autoplay={{
            delay: 5500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
        >
          {bets?.map((bet, index) => {
            const event = liveEvents?.odds?.[bet];
            const [home = {}, draw = {}, away = {}] = event ?? [];

            return (
              <SwiperSlide
                key={index}
                className='live-odds__slider-item'
              >
                <HeadToHeadCard data={event} />

                <Odds
                  homeOdd={home?.odd}
                  drawOdd={draw?.odd}
                  awayOdd={away?.odd}
                  matchId={home?.event_id}
                  externalLink={`https://betnacional.com/event/1/1/${home?.event_id}?p=GameArena`}
                />

                {isMobile ? (
                  <S.NumberPagination>
                    <Text
                      as='h4'
                      $csscolor='600'
                      $variant={{
                        typography: 'bodyTextXXSmall',
                      }}
                    >
                      {currentSlide + 1} de {size(bets)}
                    </Text>
                  </S.NumberPagination>
                ) : null}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </S.LiveOddsWrapper>
  );
};

LiveOdds.propTypes = {
  limit: PropTypes.number,
  isMobile: PropTypes.bool,
  className: PropTypes.string,
  data: PropTypes.shape({
    oddsWord: PropTypes.shape({}),
    oddsBrazil: PropTypes.shape({}),
    odds: PropTypes.shape({}).isRequired,
    live_count: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default React.memo(LiveOdds);
