import {
  size,
  take,
  isEmpty,
  groupBy,
  isObject,
  cloneDeep,
} from 'theme/utils/functions';
import { fetchOdds, fetchScoresApi, fetchScoresMatch } from '..';

const getMatchInfo = async ({ ssr = false, matchId, customHeaders }) => {
  try {
    const data = await fetchScoresMatch({
      ssr,
      matchId,
      customHeaders,
      path: '/info',
    });

    return data;
  } catch (error) {
    // @TODO: Retornar erro
    console.log('error :>> ', error);
    return {};
  }
};

export default getMatchInfo;

export const getMatchStatistics = async ({
  ssr = false,
  matchId,
  customHeaders,
}) => {
  try {
    const data = await fetchScoresMatch({
      ssr,
      matchId,
      customHeaders,
      path: '/statistics',
    });

    return data;
  } catch (error) {
    console.error(`Error in getMatchStatistics: ${error.message}`);
    return {
      error: true,
      message: error.message,
    };
  }
};

export const getMatchEventsGrouped = async ({
  ssr = false,
  matchId,
  customHeaders,
}) => {
  try {
    const data = await fetchScoresMatch({
      ssr,
      matchId,
      customHeaders,
      path: '/events/grouped',
    });

    return data;
  } catch (error) {
    // @TODO: Retornar erro
    return [];
  }
};

export const getMatchHighlights = async ({
  ssr = false,
  customHeaders,
} = {}) => {
  try {
    const data = await fetchScoresApi({
      ssr,
      customHeaders,
      path: '/match/highlights',
    });

    return data;
  } catch (error) {
    console.error(`Error in getMatchInfo: ${error.message}`);
    return {
      error: true,
      message: error.message,
    };
  }
};

export const getBetLives = async ({ ssr = false, customHeaders } = {}) => {
  try {
    const data = await fetchOdds({
      ssr,
      customHeaders,
      path: '/sports/1/markets/1/live-events',
    });

    if (!isEmpty(data.odds)) {
      data.oddsBrazil = data.odds.filter(odd =>
        odd.category_name.includes('Brasil')
      );
      data.oddsWord = data.odds.filter(
        odd => !odd.category_name.includes('Brasil')
      );

      data.oddsBrazil = groupBy({
        collection: data.oddsBrazil,
        property: 'event_id',
      });
      data.oddsWord = groupBy({
        collection: data.oddsWord,
        property: 'event_id',
      });
      data.odds = groupBy({ collection: data.odds, property: 'event_id' });
    }

    return data || {};
  } catch (error) {
    // @TODO: Retornar erro
    console.log('error :>> ', error);
    return {};
  }
};

export const getEventOdds = async ({
  ssr = false,
  matchId,
  customHeaders,
} = {}) => {
  try {
    const data = await fetchOdds({
      ssr,
      customHeaders,
      path: `/event-odds/${matchId}?languageId=1&marketIds=&outcomeIds=&statusId=1`,
    });

    if (!isEmpty(data.odds)) {
      data.odds = groupBy({
        collection: data.odds,
        property: 'market_id',
        prefix: 'market_',
      });
    }

    return data || {};
  } catch (error) {
    // @TODO: Retornar erro
    console.error(`Error in getEventOdds: ${error.message}`);
    return {
      error: true,
      menssage: error.message,
    };
  }
};

export const getMatchHomePage = async ({ ssr = false, customHeaders } = {}) => {
  try {
    const data = await fetchOdds({
      ssr,
      customHeaders,
      path: '/home-page-data',
    });

    // Ordena e retorna os 6 primeiros jogos
    if (!isEmpty(data?.events_home)) {
      data.events = groupBy({
        collection: take(data.events_home, 18),
        property: 'event_id',
      });
    }

    // Organiza a estrutura das Odds em live
    if (!isEmpty(data?.events_live?.odds)) {
      data.oddsBrazil = data.events_live.odds.filter(odd =>
        odd.category_name.includes('Brasil')
      );
      data.oddsWord = data.events_live.odds.filter(
        odd => !odd.category_name.includes('Brasil')
      );

      data.oddsBrazil = groupBy({
        collection: data.oddsBrazil,
        property: 'event_id',
      });
      data.oddsWord = groupBy({
        collection: data.oddsWord,
        property: 'event_id',
      });
      data.odds = groupBy({
        collection: data.events_live.odds,
        property: 'event_id',
      });
    }

    return data || {};
  } catch (error) {
    // @TODO: Retornar erro
    console.log('error :>> ', error);
    return {};
  }
};

export const getMatchScores = async ({
  ssr = false,
  matchId,
  customHeaders,
}) => {
  try {
    const data = await fetchScoresMatch({
      ssr,
      matchId,
      customHeaders,
      path: '/score',
    });

    return data;
  } catch (error) {
    // @TODO: Retornar erro
    return [];
  }
};

export const getMatchLives = async ({
  params,
  modifiers,
  ssr = false,
  customHeaders,
  modality = 'soccer',
}) => {
  try {
    const path = `/${modality}/match/live${isObject(params) ? `?${new URLSearchParams(params)}` : ''}`;
    const data = await fetchScoresApi({
      ssr,
      path,
      customHeaders,
    });

    const newData = cloneDeep(data);

    if (size(modifiers?.competitions)) {
      // Lista de IDs recomendados
      const recommendedIds = modifiers.competitions.map(c => c.id);

      // Lista de competições
      // const competitions = [];

      // Função para ordenar competições
      const sortCompetitionsByRecommended = (comp, ids) => {
        // Cria um mapa para acessar o índice recomendado rapidamente
        const recommendedIndexMap = new Map(
          ids.map((id, index) => [id, index])
        );

        return comp.sort((a, b) => {
          const indexA =
            recommendedIndexMap.get(a.id) !== undefined
              ? recommendedIndexMap.get(a.id)
              : Infinity;
          const indexB =
            recommendedIndexMap.get(b.id) !== undefined
              ? recommendedIndexMap.get(b.id)
              : Infinity;
          return indexA - indexB;
        });
      };

      // Ordenar competições
      const sortedCompetitions = sortCompetitionsByRecommended(
        newData.competitions,
        recommendedIds
      );

      newData.competitions = sortedCompetitions;
    }

    return {
      ...newData,
      matchesDate: params.date,
    };
  } catch (error) {
    // @TODO: Retornar erro
    console.error(
      `An error occurred while trying to return data: getMatchLives path -> /${modality}/match/live${isObject(params) ? `?${new URLSearchParams(params)}` : ''}`,
      error
    );

    return {
      error: true,
      menssage: error.message,
    };
  }
};

export const getRecommendedCompetition = async ({
  ssr = false,
  customHeaders,
  modality = 'soccer',
}) => {
  try {
    const path = `/${modality}/competition/recommended`;
    const data = await fetchScoresApi({
      ssr,
      path,
      customHeaders,
    });

    return data;
  } catch (error) {
    // @TODO: Retornar erro
    console.error(
      `An error occurred while trying to return data: getRecommendedCompetition path -> /${modality}/competition/recommended`,
      error
    );

    return {
      error: true,
      menssage: error.message,
    };
  }
};

export const getTeamResults = async ({
  teamId,
  ssr = false,
  customHeaders,
  modality = 'soccer',
}) => {
  const path = `/${modality}/team/${teamId}/results`;

  try {
    const data = await fetchScoresApi({
      ssr,
      path,
      customHeaders,
    });

    return data;
  } catch (error) {
    // @TODO: Retornar erro
    console.error(
      `An error occurred while trying to return data: getTeamResults path -> /${path}`,
      error
    );

    return {
      error: true,
      menssage: error.message,
    };
  }
};

export const getH2hLastMatches = async ({
  teamId,
  opponentId,
  ssr = false,
  customHeaders,
  modality = 'soccer',
}) => {
  const path = `/${modality}/team/${teamId}/h2h/${opponentId}/last-matches`;

  try {
    const data = await fetchScoresApi({
      ssr,
      path,
      customHeaders,
    });

    return data;
  } catch (error) {
    // @TODO: Retornar erro
    console.error(
      `An error occurred while trying to return data: getH2hLastMatches path -> /${path}`,
      error
    );

    return {
      error: true,
      menssage: error,
    };
  }
};
