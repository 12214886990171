export const NOT_FOUND_IDENTIFIER = 'Wrong identifier';
export const INVALID_SPORT_CODE = 'invalid_sport';
export const NOT_AVAILABLE = 'Não disponível';

// MODALIDADES
export const MODALITIES = [
  {
    label: 'Futebol',
    value: 'futebol',
  },
  // {
  //   label: 'Volei',
  //   value: 'volei',
  // },
  // {
  //   label: 'Basquete',
  //   value: 'basquete',
  // },
  // {
  //   label: 'Tênis',
  //   value: 'tennis',
  // },
  // {
  //   label: 'Futebol americano',
  //   value: 'futebol-americano',
  // },
];

// TIMES AlIAS
export const TIMES_NAME_ALIAS_BY_ID = {
  2010: 'CSA AL',
  22032: 'CRB AL',
  7315: 'Avaí SC',
  21838: 'ABC RN',
  2012: 'Remo PA',
  1959: 'Sport PE',
  1955: 'Bahia BA',
  2000: 'Caxias RS',
  1968: 'Santos SP',
  5926: 'Grêmio RS',
  2025: 'Ituano SP',
  1973: 'América MG',
  49202: 'Cuiabá MT',
  273503: 'Altos PI',
  1958: 'Botafogo RJ',
  5981: 'Flamengo RJ',
  1997: 'Paysandu PA',
  1979: 'Botafogo SP',
  2017: 'Confiança SE',
  290390: 'Manaus AM',
  7314: 'Atlético GO',
  1967: 'Athletico PR',
  1980: 'Juventude RS',
  2021: 'Vila Nova GO',
  21884: 'Brusque  SC',
  87202: 'Tombense MG',
  32767: 'São José RS',
  49196: 'Botafogo PB',
  32766: 'Ypiranga RS',
  39634: 'Operário PR',
  401925: 'Floresta CE',
  547561: 'Athletic MG',
  663997: 'Amazonas AM',
  32672: 'Ferroviário CE',
  1974: 'Vasco da Gama RJ',
  6982: 'Volta Redonda RJ',
  47504: 'São Bernardo SP',
  32794: 'Aparecidense GO',
  2005: 'Sampaio Corrêa MA',
};

export const MATCH_STATUS = {
  CLOSED: 'closed',
  POSTPONED: 'postponed',
  SUSPENDED: 'suspended',
  FIRST_HALF: '1nd_half',
  CANCELLED: 'cancelled',
  ABANDONED: 'abandoned',
  SECOND_HALF: '2nd_half',
  INTERRUPTED: 'interrupted',
  NOT_STARTED: 'not_started',
  MATCH_ENDED: 'match_ended',
  ENDED: ['ended', 'ap', 'match_ended'],
  LIVE: [
    'live',
    '1st_half',
    'halftime',
    '2nd_half',
    'overtime',
    '1st_extra',
    '2nd_extra',
    'awaiting_penalties',
    'penalties',
    'awaiting_extra_time',
  ],
};

export const MATCH_STATUS_NAME = {
  ap: 'Finalizado',
  aet: 'Finalizado',
  ended: 'Finalizado',
  postponed: 'Adiado',
  closed: 'Finalizado',
  halftime: 'Intervalo',
  '1st_half': '1º tempo',
  cancelled: 'Cancelado',
  '2nd_half': '2º tempo',
  overtime: 'Acrescimos',
  suspended: 'Suspendido',
  interrupted: 'Interropido',
  NOT_STARTED: 'Não iniciado',
  '1st_extra': '1º tempo extra',
  '2nd_extra': '2º tempo extra',
  penalties: 'Cobrança de pênaltis',
  awaiting_penalties: 'Aguardando pênaltis',
  extra_time_halftime: 'Intervalo tempo extra',
  awaiting_extra_time: 'Aguardando o tempo extra',
  LIVE: [
    'live',
    '1st_half',
    'halftime',
    '2nd_half',
    'overtime',
    '1st_extra',
    '2nd_extra',
    'awaiting_penalties',
    'penalties',
    'awaiting_extra_time',
  ],
};

export const MATCH_STATISTIC_NAME_ACTIONS = {
  fouls: 'Faltas',
  injuries: 'Lesões',
  throwIns: 'Laterais',
  cardsGiven: 'Cartões',
  offsides: 'Impedimentos',
  cornerKicks: 'Escanteios',
  goalKicks: 'Tiros de meta',
  redCards: 'Cartões vermelhos',
  freeKicks: 'Batidas de falta',
  shotsTotal: 'Total de chutes',
  substitutions: 'Substituições',
  shotsOnTarget: 'Chutes no gol',
  ballPossession: 'Posse de bola',
  yellowCards: 'Cartões amarelos',
  shotsSaved: 'Defesas realizadas',
  shotsBlocked: 'Chutes bloqueados',
  yellowRedCards: 'Cartões vermelhos',
  shotsOffTarget: 'Chutes fora do gol',
};

export const CLASH_RESULT_NAMES = {
  lose: 'lose',
  draw: 'draw',
  win: 'win',
};
