import styled, { css } from 'styled-components';

import breakpointsMedia from 'theme/utils/breakpointsMedia';

import { TextStyleVariants } from 'components/foundation/Text';

const sizeModifiers = {
  small: () => css`
    .info__date {
      ${TextStyleVariants({
        $variant: { typography: 'bodyTextXXSmall' },
      })};
    }

    .info__time {
      letter-spacing: -0.1rem;

      ${TextStyleVariants({
        $variant: { typography: 'bodyText' },
      })};
    }

    .info__location {
      ${TextStyleVariants({
        $variant: { typography: 'bodyTextXXSmall' },
      })};
    }
  `,
  large: () => css`
    .info__date {
      ${TextStyleVariants({
        $variant: { typography: 'bodyTextSmall' },
      })};
    }

    .info__time {
      ${TextStyleVariants({
        $variant: { typography: 'heading2' },
      })};
    }

    .info__location {
      ${TextStyleVariants({
        $variant: { typography: 'bodyTextXXXSmall' },
      })};
    }
  `,
};

export const HeadToHeadCardWrapper = styled.div`
  max-width: 100%;

  a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
`;

export const Team = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 10rem;
    min-width: 8rem;
    align-items: center;
    flex-direction: column;

    gap: ${theme.spacings.xs};

    .team {
      &__name {
        overflow: hidden;
        max-width: 9rem;
        white-space: nowrap;
        display: inline-block;
        text-overflow: ellipsis;

        &--show {
          overflow: visible;
          text-align: center;
        }
      }
    }
  `}
`;

export const Info = styled.div`
  ${({ theme, size }) => css`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: ${theme.spacings.xs};

    ${sizeModifiers[size] || sizeModifiers.small()};
  `}

  .info__time {
    padding: 0 5px;
  }

  .info__score {
    font-size: 20px;

    ${breakpointsMedia({
      md: css`
        font-size: 24px;
      `,
    })}

    &--penalties {
      font-size: 14px;

      ${breakpointsMedia({
        md: css`
          font-size: 16px;
        `,
      })}
    }
  }

  .info__competition {
    font-size: 12px;
  }
`;
